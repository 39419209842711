import React, { useState, useEffect } from 'react'
import { Add as AddIcon } from "@skbkontur/react-icons"
import Organization from './Organization'
import { Button, Input, Select, Paging, Spinner } from "@skbkontur/react-ui"
import { searchFieldTypes } from '../../pages/Home'

import styles from "../../pages/Home/Home.module.scss"

function OrganizationsList({
    totalPages,
    handleNewOrganizationModal,
    organizations,
    handleOrganizations,
    currentPage,
    onPageChange,
    searchFieldType,
    handleSearchFieldType,
    searchValue,
    handleSearchValue,
    partnerHasAtLeastOneOrganization,
    pendingOrganizations,
    availableCountries,
    products
}) {
    function updateOrganization(nextOrganization) {
        const nextOrganizations = organizations.map(organization => {
            if (organization.id === nextOrganization.id) {
                return nextOrganization
            }

            return organization
        })

        handleOrganizations(nextOrganizations)
    }

    if (!partnerHasAtLeastOneOrganization) {
        return (
            <div>
                Список клиентов пуст <br />
                <Button
                    use="link"
                    icon={<AddIcon />}
                    size="large"
                    onClick={handleNewOrganizationModal.bind(this, true)}
                >
                    Забронировать клиента
                </Button>
                <br />
            </div>
        )
    }

    return (
        <div>
            <div className={styles.head}>
                <span className={styles.title}>Список клиентов</span>
                <div className={styles.right}>
                    {' '}
                    <Button
                        use="link"
                        icon={<AddIcon />}
                        size="large"
                        onClick={handleNewOrganizationModal.bind(this, true)}
                    >
                        Забронировать клиента
                    </Button>
                </div>
            </div>

            <Input
                placeholder="Поиск"
                value={searchValue}
                onValueChange={v => handleSearchValue(v)}
                rightIcon={pendingOrganizations && <Spinner type="mini" caption="" />}
            />
            <Select
                value={searchFieldType}
                defaultValue={searchFieldTypes.name}
                items={Object.values(searchFieldTypes)}
                onValueChange={value => handleSearchFieldType(value)}
            />

            {(!organizations.length) ? (
                <div style={{ marginTop: '20px' }}>
                    По вашему запросу ничего не найдено
                </div>
            ) : (
                <table>
                    <thead>
                    <tr>
                        <th>Название организации</th>
                        <th>ИНН/УНП/БИН</th>
                        <th>Начало брони</th>
                        <th>Конец брони</th>
                        <th>Дней, осталось</th>
                        <th>Продукт</th>
                        <th>Страна</th>
                    </tr>
                    </thead>
                    <tbody>
                    {organizations.map(function (organization, i) {
                        return <Organization
                            key={i}
                            {...organization}
                            updateOrganization={updateOrganization}
                            availableCountries={availableCountries}
                            products={products}
                        />
                    })}
                    </tbody>
                </table>
            )}

            {Boolean(totalPages > 1) && (
                <div className={styles.paging}>
                    <Paging
                        activePage={currentPage}
                        onPageChange={onPageChange}
                        pagesCount={totalPages}
                    />
                </div>
            )}
        </div>
    )
}

export default OrganizationsList
