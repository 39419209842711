import React, { useState, useRef } from 'react'
import moment from "moment"
import { ValidationContainer, ValidationWrapper } from '@skbkontur/react-ui-validations'
import { Modal, Gapped, Input, Button, Spinner, Select } from '@skbkontur/react-ui'
import styles from './CreateOrganization.module.scss'
import validate from "./validate"
import request from "../../utils/request"

let identifierValueOnFocus = ''
function CreateOrganization({ addOrganization, handleClose, partnerCountry, availableCountries, products }) {
    const [form, setFormState] = useState( {
        identifier: '',
        name: '',
        contactName: '',
        phone: '',
        email: '',
        identifierStatus: '',
        country: partnerCountry,
        product: null
    })
    const [pending, handlePending] = useState(false)
    const [errors, handleErrors] = useState([])
    const [newOrganization, handleNewOrganization] = useState(null)
    const [identifierFieldStatus, handleIdentifierFieldStatus] = useState('invalid')
    const containerEl = useRef(null)

    const { identifier, name, contactName, phone, email, commentFromPartner, identifierStatus, country, product } = form
    const validation = validate(form)

    function handleForm(nextFormState) {
        setFormState(Object.assign({}, form, nextFormState))
    }

    async function handleSubmit() {
        const isFormValid = await containerEl.current.validate()
        if (!isFormValid) {
            return false
        }

        handleErrors([])
        handlePending(true)

        try {
            const organization = await request('/organizations/book', {
                method: 'POST',
                body: {
                    name,
                    contactName,
                    phone,
                    email,
                    identifier,
                    commentFromPartner,
                    identifierStatus,
                    country,
                    product
                }
            })

            addOrganization(organization)
            handleNewOrganization(organization)
        } catch (ex) {
            const { payload, status } = ex.response
            if (status === 400 && payload && Array.isArray(payload.data)) {
                handleErrors(payload.data)
            } else {
                handleErrors(['Возникла неизвестная ошибка. Не удалось забронировать организацию. Попробуйте повторить бронирование, если ошибка возобновится — обратитесь в службу поддержки'])
            }
        }

        handlePending(false)
    }

    function handleIdentifierFocus() {
        identifierValueOnFocus = identifier
    }

    async function handleIdentifierBlur() {
        if (validation.getNode(x => x.identifier).node.validation) {
            handleForm({ name: '' })
            handleIdentifierFieldStatus('invalid')

            return
        }

        if (identifierValueOnFocus !== identifier) {
            handleIdentifierFieldStatus('pending')

            try {
                const { name } = await request(`/organizations/getCompanyName?identifier=${identifier}&countryCode=${country}`)

                if (name) {
                    handleForm({ name, unpStatus: 'approved' })
                    handleIdentifierFieldStatus('success')
                }
            } catch (ex) {
                const { status } = ex.response
                handleIdentifierFieldStatus('unknown')
                let identifierStatus = 'error'


                if (status === 404) {
                    identifierStatus = 'notFound'
                }

                if (status === 408) {
                    identifierStatus = 'timeoutError'
                }

                if (status === 403) {
                    identifierStatus = 'forbiddenError'
                }

                handleForm({ identifierStatus })

                console.error(ex)
            }
        }
    }

    if (newOrganization) {
        const { name, endBookingDate } = newOrganization

        return (
            <Modal onClose={handleClose}>
                <Modal.Header>
                    Организация {name} успешно забронирована
                </Modal.Header>
                <Modal.Body>
                    Бронь действительна до <b>{moment(endBookingDate).format('DD.MM.YYYY')}</b>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Modal onClose={handleClose} width="550px">
            <Modal.Header>
                Бронирование нового клиента
            </Modal.Header>
            <Modal.Body>
                <ValidationContainer ref={containerEl}>
                    <div className={styles.field}>
                        <div className={styles.label}>Страна</div>
                        <Select
                            value={form.country}
                            items={availableCountries.map(x => [x.code, x.name])}
                            onValueChange={(country => handleForm({ country }))}
                        />
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Продукт</div>
                        <ValidationWrapper validationInfo={validation.getNode(x => x.product).get()}>
                            <Select
                                value={form.product}
                                items={products.map(x => [x.code, x.name])}
                                onValueChange={(product => handleForm({ product }))}
                            />
                        </ValidationWrapper>
                    </div>
                    {country === 'BY' && (
                        <div className={styles.field}>
                            <div className={styles.label}>УНП</div>
                            <ValidationWrapper validationInfo={validation.getNode(x => x.identifier).get()}>
                                <Input
                                    width="100%"
                                    value={identifier}
                                    onValueChange={identifier => handleForm({ identifier })}
                                    mask="999999999"
                                    onFocus={handleIdentifierFocus}
                                    onBlur={handleIdentifierBlur}
                                    placeholder="Учётный номер плательщика"
                                />
                            </ValidationWrapper>
                        </div>
                    )}
                    {country === 'KZ' && (
                        <div className={styles.field}>
                            <div className={styles.label}>БИН</div>
                            <ValidationWrapper validationInfo={validation.getNode(x => x.identifier).get()}>
                                <Input
                                    width="100%"
                                    value={identifier}
                                    onValueChange={identifier => handleForm({ identifier })}
                                    mask="999999999999"
                                    onFocus={handleIdentifierFocus}
                                    onBlur={handleIdentifierBlur}
                                    placeholder="Бизнес-идентификационный номер"
                                />
                            </ValidationWrapper>
                        </div>
                    )}
                    {country === 'KG' && (
                      <div className={styles.field}>
                          <div className={styles.label}>ИНН</div>
                          <ValidationWrapper validationInfo={validation.getNode(x => x.identifier).get()}>
                              <Input
                                width="100%"
                                value={identifier}
                                onValueChange={identifier => handleForm({ identifier })}
                                mask="99999999999999"
                                onFocus={handleIdentifierFocus}
                                onBlur={handleIdentifierBlur}
                              />
                          </ValidationWrapper>
                      </div>
                    )}
                    {country === 'UZ' && (
                      <div className={styles.field}>
                          <div className={styles.label}>ИНН</div>
                          <ValidationWrapper validationInfo={validation.getNode(x => x.identifier).get()}>
                              <Input
                                width="100%"
                                value={identifier}
                                onValueChange={identifier => handleForm({ identifier })}
                                mask="999999999"
                                onFocus={handleIdentifierFocus}
                                onBlur={handleIdentifierBlur}
                              />
                          </ValidationWrapper>
                      </div>
                    )}
                    <div className={styles.field}>
                        <div className={styles.label}>Организация</div>
                        <ValidationWrapper validationInfo={identifierFieldStatus !== 'invalid' && validation.getNode(x => x.name).get()}>
                            <Input
                                width="100%"
                                value={name}
                                alt={name}
                                onValueChange={name => handleForm({ name })}
                                disabled={identifierFieldStatus === 'pending' || identifierFieldStatus === 'success' || identifierFieldStatus === 'invalid'}
                                rightIcon={identifierFieldStatus === 'pending' && <Spinner type="mini" caption="" />}
                                placeholder="Название организации"
                            />
                        </ValidationWrapper>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Имя</div>
                        <ValidationWrapper>
                            <Input
                                width="100%"
                                value={contactName}
                                onValueChange={contactName => handleForm({ contactName })}
                                placeholder="Имя контактного лица"
                            />
                        </ValidationWrapper>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>Телефон</div>
                        <ValidationWrapper>
                            <Input
                                width="100%"
                                value={phone}
                                onValueChange={phone => handleForm({ phone })}
                                placeholder="Телефон контактного лица"
                                mask={'+999999999999999'}
                                maskChar={null}
                            />
                        </ValidationWrapper>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>E-mail</div>
                        <ValidationWrapper>
                            <Input
                                width="100%"
                                value={email}
                                onValueChange={email => handleForm({ email })}
                                placeholder="E-mail контактного лица"
                            />
                        </ValidationWrapper>
                    </div>
                    <div style={{ color: '#e3071c' }}>
                        {Array.isArray(errors)
                            ? errors.map(error => <div>{error}</div>)
                            : <div>{errors}</div>}
                    </div>
                </ValidationContainer>
            </Modal.Body>
            <Modal.Footer panel>
                <Gapped>
                    <Button
                        use="success"
                        onClick={handleSubmit}
                        loading={pending}
                    >
                        Забронировать
                    </Button>
                    <Button onClick={() => { handleClose() }}>Отмена</Button>
                </Gapped>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateOrganization
