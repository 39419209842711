import React from 'react'

function LogoPostfix() {
    return (
        <div style={{
            color: "#56916E",
            fontSize: '21px',
            verticalAlign: 'baseline',
            lineHeight: '28px'
        }}>
            Партнер
        </div>
    )
}

export default LogoPostfix
