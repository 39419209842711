import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Store from '../../store'
import Auth from "../../pages/Auth"
import Home from "../../pages/Home"
import ProtectedRoute from "../../HOC/ProtectedPage"

function App() {
    return (
        <Store>
            <Router>
                <Switch>
                    <Route exact path="/auth/" component={Auth} />
                    <ProtectedRoute exact path="/" component={Home} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        </Store>

    )
}

export default App
