import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { StoreContext } from "../../store"
import { Center, Input, PasswordInput, Button } from '@skbkontur/react-ui'
import { Logotype } from '@skbkontur/react-ui-addons'
import { Kontur } from "@skbkontur/logos";
import LogoPostfix from "../../components/LogoPostfix";
import styles from './Auth.module.scss'

import request from "../../utils/request"
import auth from "../../utils/auth"

function Auth() {
    const [login, handleLogin] = useState('')
    const [password, handlePassword] = useState('')
    const [pending, handlePending] = useState(false)
    const [error, handleError] = useState(null)
    const { handleUserInfo, handlePartnerInfo } = useContext(StoreContext)
    const history = useHistory()

    useEffect(() => {
        if (error) handleError(null)
    }, [login, password])

    async function handleSubmit() {
        handlePending(true)

        try {
            const { jwt, user } = await request('/auth/local', {
                method: 'POST',
                body: {
                    identifier: login,
                    password
                }
            })

            if (jwt && user) {
                auth.setToken(jwt, true)
                const { partner, ...rest } = user
                handleUserInfo(rest, true)

                if (!partner) {
                    throw new Error('Вы не являетесь партнером')
                }
                handlePartnerInfo(partner, true)

                return history.push('/')
            }

            throw new Error('Возникла ошибка')
        } catch (e) {
            console.error(e)
            handleError('Не удалось войти в систему')
            handlePending(false)
        }
    }

    return (
        <div className={styles.root}>
            <Center>
                <Logotype
                    konturLogo={<Kontur />}
                    productLogo={<LogoPostfix />}
                    size={30}
                />
            </Center>
            <div className={styles.title}>Вход в личный кабинет партнера</div>
            <div className={styles.field}>
                <Input
                    width="100%"
                    size="medium"
                    placeholder="Логин"
                    value={login}
                    onValueChange={v => handleLogin(v)}
                />
            </div>
            <div className={styles.field}>
                <PasswordInput
                    width="295px"
                    size="medium"
                    placeholder="Пароль"
                    value={password}
                    onValueChange={(v) => handlePassword(v)}
                />
            </div>
            <div className={styles.field}>
                <Button
                    width="100%"
                    size="medium"
                    use="success"
                    disabled={!login || !password}
                    loading={pending}
                    onClick={handleSubmit}
                >
                    Войти
                </Button>
            </div>
            <span style={{ color: '#e3071c' }}>{error}</span>
        </div>
    )
}

export default Auth
