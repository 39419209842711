// Валидация конрольной суммы УНП
// В текущей реализации не смотрим на УНП физиков, у которых первые 2 символа ничинаются с букв
// анатомия УНП — https://wiki.skbkontur.ru/pages/viewpage.action?pageId=231467863
const unpCharWeights = [29, 23, 19, 17, 13, 7, 5, 3]
function validateUnpCheckSum(unp) {
    const unpFragments = unp.split('')
    const checksum = unpFragments.reduce((result, current, i) => {
        if (i < 8) result += current * unpCharWeights[i]

        return result
    }, 0) % 11

    return checksum === parseInt(unpFragments.pop())
}

export default validateUnpCheckSum
