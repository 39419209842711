// Анатомия Казахстанского ИИН/БИН:
// https://adilet.zan.kz/rus/docs/P070000406_

function isIIN(typeNumber) {
    if (!inRange(typeNumber, 0, 6)) {
        throw new Error("invalid identification number")
    }
    return typeNumber < 4
}

function getNumberList(numbers) {
    return numbers
        .split('')
        .map((x) => parseInt(x, 10))
}

function validateSequence(numbers) {
    const standartSequence = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    const alternativeSequence = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2]
    let controlDigit

    if (numbers.length !== 12) {
        return false
    }

    controlDigit = standartSequence.reduce((accumulator, currentValue, index) =>
        accumulator + currentValue * numbers[index]) % 11

    if (controlDigit === 10) {
        controlDigit = alternativeSequence.reduce((accumulator, currentValue, index) =>
            accumulator + currentValue * numbers[index]) % 11
    }

    return controlDigit < 10
}

function inRange(needle, min, max) {
    return needle >= min && needle <= max
}

function parseIIN(numbers) {
    let year = ""
    let sex = false
    const month = parseInt(`${numbers[2]}${numbers[3]}`, 10) - 1
    if (!inRange(month, 0, 11)) {
        throw new Error("invalid month")
    }
    const day = parseInt(`${numbers[4]}${numbers[5]}`, 10)
    if (!inRange(numbers[6], 1, 6)) {
        throw new Error("invalid sex or epoch")
    }
    switch (numbers[6]) {
        case 1: {
            sex = true
        }
        case 2: {
            year = `18${numbers[0]}${numbers[1]}`
            break;
        }
        case 3: {
            sex = true
        }
        case 4: {
            year = `19${numbers[0]}${numbers[1]}`
            break;
        }
        case 5: {
            sex = true
        }
        case 6: {
            year = `20${numbers[0]}${numbers[1]}`
            break;
        }
    }
    const isLeapYear = parseInt(year, 10) % 4 === 0
    if (
        !inRange(day, 1, 31) ||
        (isLeapYear && month === 1 && !inRange(day, 1, 29))
    ) {
        throw new Error("invalid day")
    }
    return {
        birthDate: new Date(parseInt(year, 10), month, day),
        sex: sex
    }
}

function parseBIN(numbers) {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    let year

    year = parseInt(`${numbers[0]}${numbers[1]}`, 10)
    year = currentYear - 2000 >= year ? 2000 + year : 1900 + year

    const month = parseInt(`${numbers[2]}${numbers[3]}`, 10) - 1
    if (!inRange(month, 0, 11)) {
        throw new Error("invalid month")
    }
    const registrationDate = new Date(year, month)

    return {
        registrationDate: registrationDate
    }
}

function validateBIN(bin) {
    const numbers = getNumberList(bin)
    const isValidSequence = validateSequence(numbers)

    if (!isValidSequence) {
        return false
    }

    let result

    try {
        if (isIIN(numbers[4])) {
            parseIIN(numbers)
        } else {
            parseBIN(numbers)
        }

        result = true
    } catch (e) {
        console.log(e)
        result = false
    }

    return result
}

export default validateBIN
