import React, { useState, createContext } from 'react'

export const StoreContext = createContext()

function Store(props) {
    const [userInfo, handleUserInfo] = useState(null)
    const [partnerInfo, handlePartnerInfo] = useState(null)

    return (
        <StoreContext.Provider value={{ userInfo, handleUserInfo, partnerInfo, handlePartnerInfo }}>
            {props.children}
        </StoreContext.Provider>
    )
}

export default Store
