import 'whatwg-fetch'
import auth from './auth'

function parseJSON(response) {
    return response.json ? response.json() : response
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    }

    return parseJSON(response).then(responseFormatted => {
        const error = new Error(response.statusText)
        error.response = response
        error.response.payload = responseFormatted
        throw error
    })
}

function formatQueryParams(params) {
    return Object.keys(params)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join('&')
}

export default function request(url, options = {}) {
    // Set headers
    options.headers = Object.assign({
        'Content-Type': 'application/json',
    }, options.headers, {})

    const token = auth.getToken()

    if (token && url !== '/auth/local') {
        options.headers = Object.assign({
            'Authorization': `Bearer ${token}`,
        }, options.headers)
    }

    if (options && options.params) {
        const params = formatQueryParams(options.params)
        url = `${url}?${params}`
    }

    // Stringify body object
    if (options && options.body) {
        options.body = JSON.stringify(options.body)
    }

    return fetch(url, options)
        .then(checkStatus)
        .then(parseJSON)
}
