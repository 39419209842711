import { createValidator } from '@skbkontur/react-ui-validations'
import validateUnpCheckSum from './unp'
import validateBIN from './bin'

const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}) ?$/i;
const UNP_LENGTH = 9
const BIN_LENGTH = 12
const KG_INN_LENGTH = 14
const UZ_INN_LENGTH = 9
const validate = createValidator(b => {
    if (b.data.country === 'BY') {
        b.prop(
            x => x.identifier,
            b => {
                b.invalid(x => !x, 'Укажите УНП', 'submit')
                b.invalid(x => x.length !== UNP_LENGTH, 'УНП должен состоять из 9 цифр')
                b.invalid(x => !validateUnpCheckSum(x), 'Неверный формат УНП')
            }
        )
    }
    if (b.data.country === 'KZ') {
        b.prop(
            x => x.identifier,
            b => {
                b.invalid(x => !x, 'Укажите БИН', 'submit')
                b.invalid(x => x.length !== BIN_LENGTH, 'БИН должен состоять из 12 цифр')
                b.invalid(x => !validateBIN(x), 'Неверный формат БИН')
            }
        )
    }
    if (b.data.country === 'KG') {
      b.prop(
        x => x.identifier,
        b => {
          b.invalid(x => !x, 'Укажите ИНН', 'submit')
          b.invalid(x => x.length !== KG_INN_LENGTH, 'ИНН должен состоять из 14 цифр')
        }
      )
    }
    if (b.data.country === 'UZ') {
      b.prop(
        x => x.identifier,
        b => {
          b.invalid(x => !x, 'Укажите ИНН', 'submit')
          b.invalid(x => x.length !== UZ_INN_LENGTH, 'ИНН должен состоять из 9 цифр')
        }
      )
    }
    b.prop(
      x => x.product,
      b => b.invalid(x => !x, 'Укажите продукт', 'submit')
    )
    b.prop(
        x => x.organizationName,
        b => b.invalid(x => !x, 'Укажите название организации', 'submit')
    )
    b.prop(
        x => x.contactName,
        b => b.invalid(x => !x, 'Укажите имя контактного лица', 'submit')
    )
    b.prop(
        x => x.phone,
        b => {
            b.invalid(x => !x, 'Укажите телефон контактного лица', 'submit')
            b.invalid(x => x.length > 1 && x.length < 10, 'Неверный формат')
        }
    )
    b.prop(
        x => x.email,
        b => {
            b.invalid(x => !x, 'Укажите E-mail', 'submit')
            b.invalid(x => !emailRegex.test(x), 'Неверный формат E-mail')
        }
    )
})

export default validate
