import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { TopBar, Logotype } from "@skbkontur/react-ui-addons"
import { Kontur } from '@skbkontur/logos';
import { StoreContext } from "../../store"
import auth from "../../utils/auth"
import OrganizationsList from "../../components/OrganizationsList"
import CreateOrganization from "../../components/CreateOrganization"
import request from "../../utils/request"
import usePrevious from "../../HOC/usePrevious"

import styles from './Home.module.scss'
import LogoPostfix from "../../components/LogoPostfix";

const roles = {
    PartnerAdmin: 'admin',
    PartnerManager: 'manager'
}

export const searchFieldTypes = {
    name: 'Название организации',
    identifier: 'ИНН/УНП/БИН',
    email: 'E-mail',
    phone: 'Телефон'
}

let totalPages = 0
function Home() {
    const history = useHistory()
    const { userInfo, partnerInfo } = useContext(StoreContext)
    const [pendingOrganizations, handlePendingOrganizations] = useState(true)
    const [organizations, handleOrganizations] = useState([])
    const [newOrganizationModalOpened, handleNewOrganizationModal] = useState(false)
    const [currentPage, handleCurrentPage] = useState(1)
    const [searchFieldType, handleSearchFieldTypes] = useState(searchFieldTypes.name)
    const [searchValue, handleSearchValue] = useState('')
    const [partnerHasAtLeastOneOrganization, handlePartnerHasAtLeastOneOrganization] = useState(null)
    const prevSearchFieldType = usePrevious(searchFieldType);

    useEffect(() => {
        async function fetch() {
            // Если просто сменили тип поля в поиске без введенного в инпут значения, то игнорим запрос
            if (prevSearchFieldType && prevSearchFieldType !== searchFieldType && !searchValue) {
                return
            }

            try {
                let url = '/organizations?page=' + currentPage

                if (searchValue) {
                    handlePendingOrganizations(true)
                    const fieldType = Object.keys(searchFieldTypes)
                        .find(key => searchFieldTypes[key] === searchFieldType)
                    url += `&searchFieldType=${fieldType}&searchValue=${searchValue}`
                }

                const result = await request(url)

                // Если это уже не первый запрос запрос списка организаций
                // значит пользователь воспользовался пейджингом и его нужно отправить наверх
                if (totalPages) {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                }

                if (partnerHasAtLeastOneOrganization === null) {
                    handlePartnerHasAtLeastOneOrganization(Boolean(result.bookings.length))
                }

                totalPages = result.totalPages
                handleOrganizations(result.bookings)
            } catch (ex) {
                console.log(ex)
            } finally {
                handlePendingOrganizations(false)
            }
        }

        fetch()
    }, [currentPage, searchValue, searchFieldType])

    function handleLogout() {
        auth.clearToken()
        auth.clearUserInfo()
        history.push('/auth')
    }

    function addOrganization(organization) {
        if (!partnerHasAtLeastOneOrganization) {
            handlePartnerHasAtLeastOneOrganization(true)
        }

        handleOrganizations([organization, ...organizations])
    }

    return (
        <div>
            <TopBar style={{ marginBottom: '20px' }}>
                <TopBar.Start>
                    <TopBar.ItemStatic>
                        <Logotype
                            konturLogo={<Kontur />}
                            productLogo={<LogoPostfix />}
                            size={28}
                        />
                    </TopBar.ItemStatic>
                    <TopBar.Divider />
                    <TopBar.ItemStatic>
                        {partnerInfo.name || ''}
                    </TopBar.ItemStatic>
                </TopBar.Start>
                <TopBar.End>
                    <TopBar.ItemStatic>
                        {userInfo.username} ({roles[userInfo.role.name]})
                    </TopBar.ItemStatic>
                    <TopBar.Divider />
                    <TopBar.Logout onClick={handleLogout} />
                </TopBar.End>
            </TopBar>

            <div className={styles.container}>
                <OrganizationsList
                    handleNewOrganizationModal={handleNewOrganizationModal}
                    organizations={organizations}
                    handleOrganizations={handleOrganizations}
                    partnerId={partnerInfo.id}
                    availableCountries={partnerInfo.availableCountries}
                    products={partnerInfo.products}
                    currentPage={currentPage}
                    onPageChange={handleCurrentPage}
                    totalPages={totalPages}
                    searchFieldType={searchFieldType}
                    handleSearchFieldType={handleSearchFieldTypes}
                    searchValue={searchValue}
                    handleSearchValue={handleSearchValue}
                    partnerHasAtLeastOneOrganization={partnerHasAtLeastOneOrganization}
                    pendingOrganizations={pendingOrganizations}
                />
            </div>
            {newOrganizationModalOpened &&
                <CreateOrganization
                    addOrganization={addOrganization}
                    handleClose={handleNewOrganizationModal.bind(this,false)}
                    partnerCountry={partnerInfo.country}
                    availableCountries={partnerInfo.availableCountries}
                    products={partnerInfo.products}
                />}
        </div>
    )
}

export default Home

