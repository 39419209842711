import React from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from '@skbkontur/react-ui'
import styles from './ProtectedPage.module.scss'

function ErrorMessage({ error }) {
    const history = useHistory()

    return (
        <div className={styles.container}>
            <div className={styles.error}>{error}</div>
            <Link onClick={() => history.push('/auth')}>Перейти в аутентификатор</Link>
        </div>
    )
}

export default ErrorMessage
