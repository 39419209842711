import React from 'react'
import moment from "moment"

function Organization({ name, identifier, startBookingDate, endBookingDate, country, product, availableCountries, products }) {
    const productName = (products.find(p => p.id === product) || {}).name || '';
    const countryName = (availableCountries.find(c => c.id === country) || {}).name || '';

    return (
        <tr>
            <td>{name}</td>
            <td>{identifier}</td>
            <td>{moment(startBookingDate).format('DD.MM.YYYY')}</td>
            <td>{moment(endBookingDate).format('DD.MM.YYYY')}</td>
            <td>{moment(endBookingDate).diff(new Date(), 'days') + 1}</td>
            <td>{productName}</td>
            <td>{countryName}</td>
        </tr>
    )
}

export default Organization
