import React, { useState, useEffect, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { StoreContext } from "../../store"
import auth from '../../utils/auth'
import request from "../../utils/request"
import ErrorMessage from "./ErrorMessage"

function ProtectedRoute({ component: Component, ...rest }) {
    const [initialized, handleInitialized] = useState(false)
    const [error, handleError] = useState(null)
    const { userInfo, handleUserInfo, partnerInfo, handlePartnerInfo } = useContext(StoreContext)
    const token = auth.getToken()

    useEffect(() => {
        async function fetchMe() {
            try {
                const userInfoResult = await request('users/me')
                if (!userInfoResult.partner) {
                    throw new Error('Вы не являетесь партнером')
                }

                const partnerInfoResult = await request('partners/me')

                handleUserInfo(userInfoResult)
                handlePartnerInfo(partnerInfoResult)
            } catch ({ response }) {
                let message = 'Не удалось авторизоваться'

                if (!response) {
                    return handleError(message)
                }

                if (response.status === 401) {
                    message = 'Вы не аутентифицированы'
                }

                if (response.status === 403) {
                    message = 'У вас недостаточно прав'
                }

                handleError(message)
            } finally {
                handleInitialized(true)
            }
        }

        if (!userInfo && !partnerInfo) {
            fetchMe()
        } else {
            handleInitialized(true)
        }
    }, [])

    if (!token) {
        return <Redirect to={{ pathname: 'auth' }}/>
    }

    if (!initialized) {
        return null
    }

    if (error) {
        return <ErrorMessage error={error} />
    }

    return <Route {...rest} render={props => <Component {...props} />} />
}

export default ProtectedRoute
